import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "locale" ];

  connect() {
    let storedBasket = JSON.parse(sessionStorage.getItem("baskets") || "[]");
    if (storedBasket.length == 0) document.getElementById("spinner").classList.add("hidden");
   }
  
  step3() {
    const lang = document.getElementById('locale-target').value;
    let url2 = `/${lang}/agents/step3`;

    document.getElementById('spinner').classList.remove("hidden");
    const cart_layer = JSON.parse(sessionStorage.getItem("cart_layer"));
    if (cart_layer) {
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "begin_checkout",
        ecommerce: { cart_layer },
      });
      console.log("DATALAYER - begin_checkout", dataLayer);
    }
    Turbo.visit(url2, { action: "replace" });
  }

  payBooking() {
    let terms = document.getElementById("terms");
    let basketsString = sessionStorage.getItem("baskets");
    let baskets = JSON.parse(basketsString);
    if (terms.checked) {
      document.getElementById("spinner").classList.remove("hidden");
      baskets.forEach((basket) => {
        let comment = document.getElementById(`comment-${basket}`).value;
        let email = document.getElementById(`email-${basket}`).value;
        let url = `/booking_process/send_comment?basket=${basket}&comment=${comment}`;
        console.log(url);
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
      });

      let url2 = `/agents/create-payment?baskets=${baskets}`;
      console.log(url2);
      fetch(url2)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          window.location = `https://www.vivapayments.com/web/checkout?ref=${data.ref}`;
        });
    }
  }

  closeBaskets() {
    let terms = document.getElementById("terms");
    let basketsString = sessionStorage.getItem("baskets");
    let baskets = JSON.parse(basketsString);
    if ( !Array.isArray(baskets) )
      baskets = [baskets]
    if (terms.checked) {
      document.getElementById("spinner").classList.remove("hidden");
      baskets.forEach((basket) => {
        if (document.getElementById(`comment-${basket}`)) {
          let comment = document.getElementById(`comment-${basket}`).value;
          let email = document.getElementById(`email-${basket}`).value;
          let url = `/booking_process/send_comment?basket=${basket}&comment=${comment}`;
          
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              let url2
              if (document.getElementById("group_name")) {
                const name = document.getElementById("group_name").innerHTML;
                url2 = `/agents/close_basket?basket=${basket}&email=${email}&group_name=${name}`;
              } else {
                url2 = `/agents/close_basket?basket=${basket}&email=${email}`;
              }
              fetch(url2).then((response) => {
                this.removeBasketFromSession(basket)
                let modal = document.getElementById("modal");
                modal.classList.remove("hidden");
                document.getElementById("spinner").classList.add("hidden");
              });
            });
        }
       })
      
    }
  }

  finish(event) {
    window.location.href = "/";
  }

  removeBasketFromSession(value) {
    let storedBasket = sessionStorage.getItem("baskets");
    let basketArray = storedBasket ? JSON.parse(storedBasket) : [];
    if ( !Array.isArray(basketArray) )
    basketArray = [basketArray]
    if (value !== undefined) {
      let indexToRemove = basketArray.indexOf(parseInt(value));
      if (indexToRemove !== -1) {
        basketArray.splice(indexToRemove, 1);
      }
    } else {
      basketArray = [];
    }
    let updatedBasket = JSON.stringify(basketArray);
    sessionStorage.setItem("baskets", updatedBasket);
  }

}
