import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    let banner = document.getElementById('mobile-promo');
    let step3 = document.getElementById('step3');
    let banners = document.getElementsByClassName('side-promo');
    let promo = sessionStorage.getItem("promo");
    if (promo)
      banner.classList.add('hidden');
    if (step3) {
      if (banner)
        banner.classList.add('hidden');
      for (let i = 0; i < banners.length; i++) {
        let elem = banners[i];
        elem.classList.add('xl:hidden','2xl:hidden');
      };
    }
  }

  closeBanner(event) {
    let banner = document.getElementById('mobile-promo');
    if (banner)
      banner.classList.add('hidden');
    sessionStorage.setItem("promo", true);
  }

}